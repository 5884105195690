import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment.prod';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private apiUrl = '';
    private apiUrlLogin = '';

    constructor(private httpClientService: HttpClient) {
        this.setApiUrl();
    }

    private setApiUrl(): void {
        this.apiUrlLogin = environment.serverUrl;
        this.apiUrl = environment.serverUrl + '/api/';
    }

    Login<T>(resource: any, endPointUrl: string = ''): Observable<T> {
        return this.httpClientService.post<T>(this.apiUrlLogin + endPointUrl, resource);
    }

    GetAll<T>(endPointUrl: string = ''): Observable<T> {
        return this.httpClientService.get<T>(this.apiUrl + endPointUrl);
    }

    Get<T>(Id: any, endPointUrl: string = '', paramUrl: string = ''): Observable<T> {
        return this.httpClientService.get<T>(this.apiUrl + endPointUrl + '/' + Id + paramUrl);
    }

    GetPdf(endPointUrl: string = ''): Observable<any> {
        return this.httpClientService.get(this.apiUrl + endPointUrl, { responseType: 'blob' });
    }

    GetWithoutId<T>(endPointUrl: string = ''): Observable<T> {
        return this.httpClientService.get<T>(this.apiUrl + endPointUrl);
    }

    GetWithParam<T>(Id: any, endPointUrl: string = '', paramUrl: string = ''): Observable<T> {
        return this.httpClientService.get<T>(this.apiUrl + endPointUrl + '/' + paramUrl + Id);
    }

    Create<T>(resource: any, endPointUrl: string = ''): Observable<T> {
        return this.httpClientService.post<T>(this.apiUrl + endPointUrl, resource);
    }

    Update<T>(resource: any, endPointUrl: string = ''): Observable<T> {
        return this.httpClientService.put<T>(this.apiUrl + endPointUrl, resource);
    }

    UpdateWithoutBody<T>(endPointUrl: string = ''): Observable<T> {
        return this.httpClientService.put<T>(this.apiUrl + endPointUrl, null);
    }

    Delete<T>(endPointUrl: string = ''): Observable<any> {
        return this.httpClientService.delete<T>(this.apiUrl + endPointUrl);
    }
}
