<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Gratis Video's van Theoriecoach</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <video width="100%" height="100%" controls  preload="none" poster="assets/img/vraag1.jpg">
                            <source src="assets/video/vraag1.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div class="post-content">
                        <h3>Hoelang is een rijbewijs in het algemeen geldig?</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <video width="100%" height="100%" controls  preload="none" poster="assets/img/vraag2.jpg">
                            <source src="assets/video/vraag2.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div class="post-content">
                        <h3>Welk deel van de weg is de rijbaan?</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <video width="100%" height="100%" controls  preload="none" poster="assets/img/vraag3.jpg">
                            <source src="assets/video/vraag3.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div class="post-content">
                        <h3>Deze zwangere bestuurder gebruikt de driepuntsgordol. Moet dat?</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <video width="100%" height="100%" controls  preload="none" poster="assets/img/vraag4.jpg">
                            <source src="assets/video/vraag4.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div class="post-content">
                        <h3>Bij welk verkeersbord mag een bus je inhalen?</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <video width="100%" height="100%" controls  preload="none" poster="assets/img/vraag5.jpg">
                            <source src="assets/video/vraag5.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div class="post-content">
                        <h3>Wie moet je bij dit bord voor laten gaan?</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <video width="100%" height="100%" controls  preload="none" poster="assets/img/vraag6.jpg">
                            <source src="assets/video/vraag6.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div class="post-content">
                        <h3>Je rijdt om 20:00 's avonds op de autosnelweg en je ziet dit bord. Wat is dan je toegestane maximumsnelheid?</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <video width="100%" height="100%" controls preload="none" poster="assets/img/vraag7.jpg">
                            <source src="assets/video/vraag7.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div class="post-content">
                        <h3>Mag je na dit bord de doorgetrokken streep overschrijden?</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
