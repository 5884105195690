<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_full_full_tc.png" alt="logo"></a>
                    <p>theoriecoach.nl is dé website bij waar je je  door middel van onze video cursus en proefexamens uitstekend voorbereid op het officiële CBR examen.</p>
                    <ul class="social-link">
                        <li><a href="https://www.instagram.com/theoriecoach.nl/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/theoriecoach.nl/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/">Over Ons</a></li>
                        <li><a href="https://front.theoriecoach.nl">Inloggen</a></li>
                        <li><a routerLink="/gratisvideos">Gratis Videos</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <ul class="footer-links-list">
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Algemene Voorwaarden</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Klantenservice</h3>
                    <ul class="footer-links-list">
                        <li>Heb je vragen? Je kunt ons bellen of WhatsAppen</li>
                        <li><a href="mailto:hello@odemy.com"> KVK 78633419</a></li>
                        <li><a href="tel:+557854578964"> info@theoriecoach.nl</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class='bx bx-copyright'></i>{{date}} Theoriecoach. Website by <a target="_blank" href="https://sonamu.nl/">SONAMU</a></p>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
