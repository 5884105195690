import { Component, OnInit } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {DataService} from '../../../core/data.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
const routes = {
    getAllPackages: () => `packages`,
};
@Component({
  selector: 'app-courses-style-three',
  templateUrl: './courses-style-three.component.html',
  styleUrls: ['./courses-style-three.component.scss']
})
export class CoursesStyleThreeComponent extends DataService implements OnInit {

    public pakketten: any[] = [];
    constructor(http: HttpClient, private router: Router) {
        super(http);
    }

  ngOnInit(): void {
        this.getAllPackages().subscribe((response) => {
            console.log(response);
            this.pakketten = response.data;
        });
  }

    getAllPackages(): Observable<any> {
        return this.GetAll(routes.getAllPackages()).pipe(
            tap((response: any) => {}),
            finalize(() => {}),
            catchError((val: any) => {
                return throwError(val);
            })
        );
    }

    goToCheckout(id, price, name): void {
        this.router.navigateByUrl('/checkout/' + id + '/' + price + '/' + name);
    }
}
