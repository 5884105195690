<div class="main-banner" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                    <h1>Theorie examen oefenen en in 1 keer slagen voor je theorie-examen</h1>
                    <p>Leuk en makkelijk online leren en oefenen voor je theorie-examen met de interactieve lesmethode van de Theorie Coach. Met onze videocursus leer je op een leuke én verantwoorde manier alles wat je moet weten voor het CBR theorie-examen. Een effectieve uitleg met leuke, versterkende animaties om de theorie te leren en nooit meer te vergeten!</p>
                    <a routerLink="/theoriepakketten" class="default-btn"><i class="flaticon-"></i>Bekijk onze pakketten<span></span></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-courses-list">
                    <div class="row">
                        <div class="col-lg-10 col-md-8" style="margin: 0 auto">
                            <div class="single-courses-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-1" class="d-block image">
                                        <img src="assets/img/tcbanner3.jpg" alt="image">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="banner-shape1"><img src="assets/img/banner-shape1.png" alt="image"></div>
                    <div class="banner-shape2"><img src="assets/img/banner-shape2.png" alt="image"></div>
                    <div class="banner-shape3"><img src="assets/img/banner-shape3.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</div>
