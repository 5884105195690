<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Checkout</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="checkout-area ptb-100">
    <div class="container">
        <div *ngIf="haserror" class="alert alert-danger">
            <p *ngFor="let error of errors | keyvalue">{{error.value}}</p>
        </div>
        <form [formGroup]="checkoutForm">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">Contact informatie</h3>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" class="form-control" formControlName="email"
                                        [ngClass]="{
                                        'is-invalid': (f.email.touched && f.email.invalid) || (formSubmitted && f.email.invalid),
                                        'is-valid': f.email.touched && f.email.valid
                                      }"
                                    >
                                    <div class="invalid-feedback" translate *ngIf="f.email.errors?.required">Email is verplicht</div>
                                    <div class="invalid-feedback" translate *ngIf="f.email.errors?.email">Voer een correcte email in</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Wachtwoord <span class="required">*</span></label>
                                    <input type="password" class="form-control" formControlName="password"
                                           [ngClass]="{
                                        'is-invalid': (f.password.touched && f.password.invalid) || (formSubmitted && f.password.invalid) || checkoutForm.errors?.notSame,
                                        'is-valid': f.password.touched && f.password.valid
                                      }">
                                    <div class="invalid-feedback" translate *ngIf="f.password.errors?.required">Wachtwoord is verplicht</div>
                                    <div class="invalid-feedback" translate *ngIf="checkoutForm.errors?.notSame">Wachtwoorden komen niet overeen</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Wachtwoord herhalen <span class="required">*</span></label>
                                    <input type="password" class="form-control" formControlName="password_confirmation"[ngClass]="{
                                        'is-invalid': (f.password_confirmation.touched && f.password_confirmation.invalid) || (formSubmitted && f.password_confirmation.invalid) || checkoutForm.errors?.notSame,
                                        'is-valid': f.password_confirmation.touched && f.password_confirmation.valid
                                      }">
                                    <div class="invalid-feedback" translate *ngIf="f.password_confirmation.errors?.required">Herhaal uw wachtwoord a.u.b.</div>
                                    <div class="invalid-feedback" translate *ngIf="checkoutForm.errors?.notSame">Wachtwoorden komen niet overeen</div>
                                </div>
                            </div>
                            <hr>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Naam <span class="required">*</span></label>
                                    <input type="text" class="form-control" formControlName="name">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Achternaam </label>
                                    <input type="text" class="form-control" formControlName="surname">
                                </div>
                            </div>
<!--                            <div class="col-lg-12 col-md-6">-->
<!--                                <div class="form-group">-->
<!--                                    <label>Adres </label>-->
<!--                                    <input type="text" class="form-control">-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-lg-6 col-md-6">-->
<!--                                <div class="form-group">-->
<!--                                    <label>Stad </label>-->
<!--                                    <input type="text" class="form-control">-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-lg-6 col-md-6">-->
<!--                                <div class="form-group">-->
<!--                                    <label>Postcode </label>-->
<!--                                    <input type="text" class="form-control">-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="order-details">
                        <h3 class="title">Uw pakket</h3>
                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th scope="col">Naam</th>
                                    <th scope="col">Totaal</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td class="product-name">
                                        <a routerLink="/single-products">{{name}}</a>
                                    </td>
                                    <td class="product-total">
                                        <span class="subtotal-amount">€{{price}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="order-subtotal">
                                        <span>Subtotaal</span>
                                    </td>
                                    <td class="order-subtotal-price">
                                        <span class="order-subtotal-amount">€{{price}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="total-price">
                                        <span>Order Totaal</span>
                                    </td>
                                    <td class="product-subtotal">
                                        <span class="subtotal-amount">€{{price}}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="payment-box">
                            <div class="payment-method">
                                <p>
                                    <input type="radio" id="ideal" name="radio-group" checked>
                                    <label for="ideal">iDeal</label>
                                </p>
                            </div>
                            <button type="button" (click)="checkout()" class="default-btn"><i class="flaticon-shopping-cart"></i>Betalen<span></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
