<div class="funfacts-and-feedback-area ptb-100" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-content">
                    <span class="sub-title">CBR gericht theorie oefenen examen</span>
                    <h2>Oefen nu online examengericht voor je auto theorie-examen.</h2>
                    <p>Klaar voor het CBR examen? Bewijs het jezelf! Weet precies wat je te wachten staat: geen verrassingen. Vergelijkbare vragen en een soortgelijke omgeving zoals bij het CBR examen om jezelf perfect mee te testen. Slaag je bij Theorie Coach en je slaagt ook bij het CBR.</p>
                    <div class="feedback-slides">
                        <owl-carousel-o [options]="feedbackSlides">
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <span class="sub-title">Wat zeggen anderen?</span>
                                    <p>Na drie keer zakken heb ik de video cursus van Theorie Coach geprobeerd en het is in een keer gelukt! videos zijn van top kwaliteit en een goede uitleg! de oefenvragen en de proefexamens hebben mij ontzettend goed geholpen.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <div class="title">
                                            <h3>- Wouter</h3>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <span class="sub-title">Wat zeggen anderen?</span>
                                    <p>Hele goede uitleg met veel informatie, maar toch boeiend. Het werd overgebracht op een manier dat je bleef luisteren. Omdat het online is kon ik op ieder moment van de dag oefenen dat was zeker een pluspunt . En het belangrijkste: gelijk geslaagd!!!!! Zeker een Aanrader!! Bedankt!</p>
                                    <div class="client-info d-flex align-items-center">
                                        <div class="title">
                                            <h3>- Sophie</h3>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-item">
                                    <span class="sub-title">Wat zeggen anderen?</span>
                                    <p>Ik raad het zeker aan. Super duidelijke uitleg per onderdeel. De online versie is geweldig. Je kunt alle filmpjes op je gemak en op je eigen tempo bekijken (meerdere keren). Daarnaast zijn de oefen examens ook super goed om zo veel mogelijk te oefenen.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <div class="title">
                                            <h3>- Anouk Ridder</h3>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="4">00</span>+ uur</h3>
                                <p>Video cursus</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="500">00</span>+</h3>
                                <p>Oefenvragen</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="250">00</span>+</h3>
                                <p>Geslaagden</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="100">00</span>%</h3>
                                <p>CBR gericht</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <app-video></app-video>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/donotenter.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>
