<div class="video-box">
    <div class="image">
        <video width="100%" height="100%" controls preload="none" poster="assets/img/introposter.jpg">
            <source src="assets/video/INTRO_homepage.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
<!--    <a href="#" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>-->
    <div class="shape10"><img src="assets/img/shape9.png" alt="image"></div>
</div>
