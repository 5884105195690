import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {DataService} from '../../../core/data.service';
import {HttpClient} from '@angular/common/http';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {environment} from "../../../../environments/environment.prod";

const routes = {
    checkout: () => `newuserbuypackage`,
};

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent extends DataService implements OnInit {
    private pakketid = '';
    public price = '';
    public name = '';
    public errors: any = {};
    public haserror = false;

    public checkoutForm: FormGroup;
    public hasFormErrors = false;
    public formSubmitted = false;

    constructor(private activatedRoute: ActivatedRoute, http: HttpClient, private formBuilder: FormBuilder,) {
        super(http);
        this.activatedRoute.params.subscribe(params => {
            this.pakketid = params.id;
            this.price = params.price;
            this.name = params.name;
        });
    }

    ngOnInit(): void {
    this.checkoutForm = this.formBuilder.group({
            name: ['', Validators.required],
            surname: '',
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            password_confirmation: ['', Validators.required],
            package_id: [this.pakketid, Validators.required],
            return_link: environment.redirectUrl,
        }, {validators: this.checkPasswords });
    }
    get f(): any {return this.checkoutForm.controls; }
    checkout(): void {
        this.hasFormErrors = false;
        this.formSubmitted = true;
        const controls = this.checkoutForm.controls;
        if (this.checkoutForm.invalid) {
            Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
            this.hasFormErrors = true;
            return;
        } else {
            const formData = {
                name: this.checkoutForm.value.name + ' ' + this.checkoutForm.value.surname,
                email: this.checkoutForm.value.email,
                password: this.checkoutForm.value.password,
                password_confirmation: this.checkoutForm.value.password_confirmation,
                return_link: this.checkoutForm.value.return_link,
                package_id: this.checkoutForm.value.package_id,
            };
            this.checkoutApi(formData).subscribe((response) => {
                window.location.href = JSON.parse(response.data.url);
            });
        }
        this.haserror = false;

    }

    checkoutApi(formData): Observable<any> {
        return this.Create(formData, routes.checkout()).pipe(
            tap((response: any) => {
            }),
            finalize(() => {
            }),
            catchError((val: any) => {
                this.errors = val.error.errors;
                this.haserror = true;
                return throwError(val);
            })
        );
    }

    checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
        const pass = group.get('password').value;
        const confirmPass = group.get('password_confirmation').value;
        return pass === confirmPass ? null : { notSame: true };
    }
}
