<div class="get-instant-courses-area">
    <div class="container">
        <div class="get-instant-courses-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-12">
                    <div class="get-instant-courses-content">
                        <span class="sub-title">Online videocursus</span>
                        <h2>Slaag snel & gemakkelijk voor het Theorie-examen!</h2>
                        <p>Je kunt de cursus volgen op je telefoon, computer, laptop of tablet waar je ook bent. De Videocursus bevat de laatste vragen en is altijd up-to-date. </p>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12">
                    <div class="get-instant-courses-image">
                        <img src="assets/img/mockup.jpg" alt="image">
                        <div class="shape7"><img src="assets/img/shape4.png" alt="image"></div>
                        <div class="shape6"><img src="assets/img/shape6.png" alt="image"></div>
                    </div>
                </div>
            </div>
            <div class="shape5"><img src="assets/img/shape5.png" alt="image"></div>
        </div>
    </div>
</div>
